(function () {
    angular.module('informaApp')
        .component('usageFilterModal', {
            templateUrl: 'components/admin-sections/usage-section/usage-filter-modal/template.html',
            controller: UsageFilterModalController,
            bindings: {
                companies: '<',
                onSubmit: '<',
                onInitialize: '<'
            }
        });

    function UsageFilterModalController() {
        this.onFilterInit = (options) => {
            this.filterOptions = options;
        };

        this.submit = () => {
            if (this.onSubmit) {
                this.onSubmit(_.clone(this.filterOptions.getFilter()));
            }
        };
    }
})();
